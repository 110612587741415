import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { getLocale } from './config'


const init = () => {
  i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: getLocale(), // TODO: Change locale to configure from domain
    interpolation: {
      escapeValue: false
    },
    debug: false,
    backend: {
      loadPath: `https://cms.az1.kilroy.eu/api/v1/translations/legacyGetAll?culture=${getLocale()}`, // Temporary solution to get translations directly from cms. TODO: cleanup
      customHeaders: () => ({
        'origin': `${window.location.protocol}//${window.location.host}`
      }),
    },
  })
}

export default init
