import React, { useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Box, CircularProgress, Modal, Typography } from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import { PrimaryButton } from '../../blocks/buttons/primary-button'

function CountDown(props) {
  const { created, orderId, backButtonUrl, handleOnFinished, t } = props
  const [countdownDate, setCountdownDate] = useState(null)
  const [origDate, setOrigDate] = useState(null)
  const [targetDate, setTargetDate] = useState(null)
  const [countdownLabel, setCountdownLabel] = useState('')
  const [countdownValue, setCountdownValue] = useState(100)
  const [updateWatcher, setUpdateWatcher] = useState(null)
  const [completed, setCompleted] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  
  useEffect(() => {
    if ( !countdownDate || !targetDate || !origDate || completed ) {
      return
    }
    if ( new Date() > targetDate ) {
      setCompleted(true)
      setModalOpen(true)
      setCountdownValue(0)
      handleOnFinished()
    } else {
      setCountdownLabel(`${countdownDate.toTimeString().substr(3,2) }:${countdownDate.toTimeString().substr(6,2)}`)
      setCountdownValue(() => 100 - (100 / (targetDate.getTime() - origDate.getTime()) * (Date.now()  - origDate.getTime() )))
    }
  }, [countdownDate, targetDate, origDate, completed, handleOnFinished])

  function setup() {
    
    const storedTargetDate = localStorage.getItem(`countdownTargetDate-${orderId}`)
    if ( storedTargetDate ) {
      setTargetDate(new Date(storedTargetDate))
    } else {
      const newTargetDate = new Date(new Date(created).getTime() + ( 1000 * 60 * 10 ))
      setTargetDate(newTargetDate) 
      localStorage.setItem(`countdownTargetDate-${orderId}`, newTargetDate)
    }

    const storedOrigDate = localStorage.getItem(`countdownOrigDate-${orderId}`)
    if ( storedOrigDate ) {
      setOrigDate(new Date(storedOrigDate))
    } else {
      const newOrigDate = new Date(created)
      
      setOrigDate(newOrigDate) 
      localStorage.setItem(`countdownOrigDate-${orderId}`, newOrigDate)
    }
  }

  const handleModalClose =  () => {
    setModalOpen(false)
  }
  
  useEffect(() => {
    const diffDate = new Date(targetDate - new Date())
    setCountdownDate(diffDate)   
  }, [targetDate, updateWatcher])  

  useEffect(() => {
    setup()
    setInterval(() => {
      if ( !completed ) {
        setUpdateWatcher(new Date()) 
      } 
    },1000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])  


  
  const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  }
  
  const countDownLabelStyles = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
      

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyles} >
          <WarningIcon color='warning' fontSize='large' />
          <Typography variant='h4' sx={{ my: 1 }}>
            {t('Kilroy.Payment.AdyenSkin.CountDownExpiredHeadline')}
          </Typography>
          <Typography variant='body1' sx={{ my: 1 }}>
            {t('Kilroy.Payment.AdyenSkin.CountDownExpiredText')}
          </Typography>
          {backButtonUrl && <PrimaryButton color="primary" href={backButtonUrl}>{t('Kilroy.Payment.AdyenSkin.Button.Back', 'Back')}</PrimaryButton>}
        </Box>
      </Modal>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end',flexDirection: {xs: 'row-reverse', sm: 'row'},maxWidth: '100%', my: {xs: 2, sm: 0}}}>
        { !completed && 
        <>
          <Typography variant='body2' sx={{ mx: 2, fontStyle: 'italic', textAlign: {xs: 'left', sm: 'right'}}}>
            {t('Kilroy.Payment.AdyenSkin.CountDownInfo')}
          </Typography>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size={60} thickness={5} variant='determinate' value={countdownValue} />
            <Box
              sx={countDownLabelStyles}
            >
              <Typography variant='caption' component='div' color='text.secondary'>
                {countdownLabel}
              </Typography>
            </Box>
          </Box>
        </>

        }
      </Box>
    </>
  )
}

CountDown.defaultProps = {
  backButtonUrl: null,
}

CountDown.propTypes = {
  orderId: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  backButtonUrl: PropTypes.string,
  handleOnFinished: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}


export default withTranslation()(CountDown)