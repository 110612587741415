import React from 'react'
import { getBrand } from '../common/config'
import {ReactComponent as JyskRejsebureauLogo} from '../assets/jysk-rejsebureau/logo.svg'
import {ReactComponent as KilroyLogo} from '../assets/kilroy/logo.svg'
import {ReactComponent as BennsLogo} from '../assets/benns/logo.svg'
import {ReactComponent as WinbergLogo} from '../assets/winberg/logo.svg'
import {ReactComponent as IsicLogo} from '../assets/isic/logo.svg'

const BrandLogos = {
    benns: BennsLogo,
    isic: IsicLogo,
    jr: JyskRejsebureauLogo,
    kilroy: KilroyLogo,
    winberg: WinbergLogo,
}

export const BrandLogo = ({ ...props }) => {
    const Logo = BrandLogos[getBrand()]

    return <Logo {...props} />
}