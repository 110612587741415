import React from 'react'
import PropTypes, { arrayOf } from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { grey } from '@mui/material/colors'
import { nanoid } from 'nanoid'

export const CustomTable = ({ tableRows, highligtRowIndex }) => (
  <TableContainer sx={{'& tbody': {borderBottom: `2px solid ${grey[300]}`}}}>
    <Table size="small" sx={{pb: 2}}>
      <TableBody >
          {tableRows && tableRows.map((row, rowIndex) => (
            <TableRow
              key={nanoid()}
              sx={{
                'th': {
                  textTransform: 'uppercase',
                  fontWeight: 600
                }
              }}
            >
              {row && row.map((col, colIndex) => (
                <TableCell
                  key={nanoid()}
                  component={colIndex === 0 ? 'th' : 'td'}
                  align={colIndex !== 0 ? 'right' : 'left'}
                  size="small"
                  sx={{ 
                    padding: '2px 5px',
                    border: 0,
                    color: rowIndex !== highligtRowIndex ? grey[700] : 'black',
                    bgcolor: rowIndex !== highligtRowIndex ? 'transparent' : grey[200],
                    borderTop: rowIndex !== highligtRowIndex ? 0 : `2px solid ${grey[300]}`,
                  }}
                >
                  {col}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
)

CustomTable.propTypes = {
  tableRows: PropTypes.arrayOf(
    arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
      ])
    )  
  ).isRequired,
  highligtRowIndex: PropTypes.number
}

CustomTable.defaultProps = {
  highligtRowIndex: null
}

