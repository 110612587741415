import React from 'react'
import Container from '@mui/material/Container'
import { grey } from '@mui/material/colors'
import PropTypes from 'prop-types'
import { Logo } from '../../blocks/logos/logo'

export const ContainerLogo = ({children, logoMargin}) => (
    <Container maxWidth="false" sx={{
        bgcolor: grey[100],
        minHeight: '100vh',
        padding: 0
      }}>
        <Container maxWidth="lg" sx={{ p: 3}}>
          <Logo color="primary" size="large" margin={logoMargin} />
        {children}
        </Container>
    </Container>
)

ContainerLogo.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string
    ]).isRequired,
    logoMargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

ContainerLogo.defaultProps = {
  logoMargin: 0
}