import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { CircularProgress } from '@mui/material'
import * as Sentry from '@sentry/react'
import i18n from './common/i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { configLoader, getBrand, getBrandDisplayName, getConfig } from './common/config'
import { getBrandTheme } from './helpers/theme-helper'
import { ContainerLogo } from './composite/container/container-logo'

const LoadingPage = () => (
  <ContainerLogo>
    <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </ContainerLogo>
)

/* 
  Document head values are only set this way to avoid creating
  docker images for each brand in order to build the static html file
  with these values.

  This option was choosen only because the site isn't
  optimized for Search Engines
*/
const changeDocumentHead = () => {
  document.title = getBrandDisplayName()
  const brand = getBrand()
  const relLinks = [
    {
      type: 'icon',
      fileName: 'favicon.ico',
    },
    {
      type: 'apple-touch-icon',
      fileName: 'logo192.png',
    },
    {
      type: 'manifest',
      fileName: 'manifest.json',
    },
    {
      type: 'stylesheet',
      fileName: 'index.css',
    },
  ]

  relLinks.forEach((relLink) => {
    const link = document.createElement('link')
    link.rel = relLink.type
    link.href = `${process.env.PUBLIC_URL}/${brand}/${relLink.fileName}`

    document.getElementsByTagName('head')[0].appendChild(link)
  })
}

configLoader('/config/config.json')
.then(() => {
  if (getConfig('SENTRY_DSN') && getConfig('SENTRY_DSN') !== '') {
    Sentry.init({
      dsn: getConfig('SENTRY_DSN'),
      environment: getConfig('SENTRY_ENV')
    })
  }
  i18n()
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={getBrandTheme[getBrand()]}>
        <Suspense fallback={<LoadingPage />}>
          <App />
        </Suspense>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
  changeDocumentHead()
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
