import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { PaymentPage } from './pages/payment'
import { NotFoundPage } from './pages/not-found'

function App() {
  return <Router>
    <Switch>
      <Route path="/:lang/payment">
        <PaymentPage />
      </Route>
      <Route path="/payment">
        <PaymentPage />
      </Route>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  </Router>
}

export default App
