import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: ['Roboto', 'Sans Serif'].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 900,
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.875rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 900,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 700,
    }
  },
  palette: {
    primary: {
      main: '#40b8b8',
      dark: '#346562',
      light: '#D9F1F1',
      contrastText: '#fff',
    },
    secondary: {
      main: '#346562',
    },
    muted: {
      main: '#b7b7b7',
      dark: '#5e5c5c',
      light: '#e0dcdc',
      contrastText: '#fff',
    },
    logo: {
      primary: '#000000',
      secondary: '#FFFFFF',
      black: '#000000',
      white: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          fontWeight: 500,
          textTransform: 'capitalize',
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'small' && {
            fontSize: '.78em',
            letterSpacing: '0.025em'
          }),
        })
      }
    }
  },
})