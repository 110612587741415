/* eslint-disable no-restricted-syntax */
import React from 'react'
import { 
  Box,
  Accordion ,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { CustomTable } from '../../composite/tables/custom-table'

export const additionalDataTables = (data, t) => {

  const passengersAmount = Number(data['airline.passengers.amount'])
  const legsAmount = Number(data['airline.legs.amount'])

  const getAirlineDataTables = () => {
    const passengersRegex = /airline.passenger[0-9]/
    const legsRegex = /airline.leg[0-9]/
    const generalBlackList = [
      'airline.passenger_name',
      'airline.flight_date',
      'airline.legs.amount',
      'airline.passengers.amount',
      'openinvoicedata.merchantData',
      'openinvoicedata.recipientFirstName',
      'openinvoicedata.recipientLastName'
    ]
    const generalKeys = Object.keys(data).filter( key => !key.match(passengersRegex) && !key.match(legsRegex) && !generalBlackList.includes(key))
    const generalData = generalKeys.map( key => {
      const keySplitArray = key.split('.')
      const trimmedKey = keySplitArray[keySplitArray.length - 1]
      const translatedKey = t(`Kilroy.Payment.AdyenSkin.AirlineData.${trimmedKey}`, trimmedKey)
    
      return [translatedKey, data[key]]
    })

    return generalData.length ?
    <> 
        <Typography variant="body1" sx={{ py: 1, fontWeight: '700'}}>{t('Kilroy.Payment.AdyenSkin.AirlineData.general', 'General')} </Typography>
        <CustomTable
          highligtRowIndex={generalData.length}
          tableRows={[
            ...generalData,
          ]}
        />
    </>
    : 
    <></>
  }

  const getPassengerData = (index) => {
    const passengerRegex = `airline.passenger${index + 1}`
    const passengerKeys = Object.keys(data).filter( key => key.match(passengerRegex))
    const passengerData = passengerKeys.map( key => {
      const keySplitArray = key.split('.')
      const trimmedKey = keySplitArray[keySplitArray.length - 1]
      const translatedKey = t(`Kilroy.Payment.AdyenSkin.AirlineData.${trimmedKey}`, trimmedKey)
  
      return [translatedKey, data[key]]
    })

    return passengerData
  }

  const getLegData = (index) => {
    const legRegex = `airline.leg${index + 1}`
    const legsBlackList = [
      'fare_base_code'
    ]
    const legKeys = Object.keys(data).filter( key => {
      const keySplitArray = key.split('.')
      const trimmedKey = keySplitArray[keySplitArray.length - 1]

      return key.match(legRegex) &&  !legsBlackList.includes(trimmedKey) 
    })
    const legData = legKeys.map( key => {
      const keySplitArray = key.split('.')
      const trimmedKey = keySplitArray[keySplitArray.length - 1]
      const translatedKey = t(`Kilroy.Payment.AdyenSkin.AirlineData.${trimmedKey}`, trimmedKey)
  
      return [translatedKey, data[key]]
    })

    return legData
  }

  const getPassengersTables = () => {
    const emptyLoopArray = [...Array(passengersAmount)]

    return emptyLoopArray.map((e, index) => (
      <Box key={index}>  
        <Typography variant="body1" sx={{ py: 1, fontWeight: '700'}}>{t('Kilroy.Payment.AdyenSkin.AirlineData.passenger', 'Passenger')} { index + 1 }</Typography>
        <CustomTable
          highligtRowIndex={getPassengerData(index).length}
          tableRows={[
            ...getPassengerData(index),
          ]}
        />
      </Box>
    ))
  }

  const getLegsTables = () => {
    const emptyLoopArray = [...Array(legsAmount)]

    return emptyLoopArray.map((e, index) => (
      
      <Box key={index}>  
        <Typography variant="body1" sx={{ py: 1, fontWeight: '700'}}>{t('Kilroy.Payment.AdyenSkin.AirlineData.leg', 'Leg')} { index + 1 }</Typography>
        <CustomTable
          highligtRowIndex={getLegData(index).length}
          tableRows={[
            ...getLegData(index),
          ]}
        />
      </Box>
    ))
  }

  return (
    <Accordion sx={{p: {xs: 0, sm: 3}, mb: 3}} defaultExpanded={false}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ minHeight: '0 !important', '& .MuiAccordionSummary-content': { m: {xs: '8px 0 !important', sm: '0 !important'}} }}
      >
        <Typography variant="h6" >{ t('Kilroy.Payment.AdyenSkin.AirlineData.header', 'Airline data') }</Typography>
      </AccordionSummary>
      <AccordionDetails>
          {
            getAirlineDataTables()
          }
          {
            getPassengersTables()
          }
          {
            getLegsTables()

          }
      </AccordionDetails>
    </Accordion> 
  )
}