export const languageCodeDomains = {
  dk: 'da-DK',
  no: 'nb-NO',
  se: 'sv-SE',
  net: 'en-GB',
  uk: 'en-GB',
  fi: 'fi-FI',
  is: 'is-IS',
  be: 'nl-BE',
  nl: 'nl-NL',
  pl: 'pl-PL'
}
