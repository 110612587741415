export const formatPrice = (value, locale, currency) => {
  if (value) {
    return new Intl.NumberFormat(locale, {
      style: currency ? 'currency' : 'decimal',
      currency,
      minimumFractionDigits: 0,
    }).format(value)
  }

  return ''
}