import { languageCodeDomains } from '../helpers/language-codes-mapper'

const fetch = require('node-fetch')


export const getConfig = key => {
    if(!window.CONFIG) throw new Error('window.CONFIG was not found')

    if(!window.CONFIG[key] && window.CONFIG[key] !== '') throw new Error(`Config key "${key}" was not found in window.CONFIG`)

    if(window.CONFIG[key].indexOf('%') === 0) throw new Error(`Config key "${key}" has the value "${window.CONFIG[key]}" which indicates that it isn't set`)

    return window.CONFIG[key]
}

export const getBffUrl = () => getConfig('BFF_URL')

export const getBffApiUrl = () => `${getBffUrl()}api/`

export const getAdyenClientKey = () => getConfig('PAYMENT_CLIENT_KEY')

export const getBrand = () => getConfig('BRAND')

export const getBrandDisplayName = () => getConfig('BRAND_DISPLAY_NAME')

export const getAdyenEnvironment = () => getConfig('PAYMENT_ENVIRONMENT')

export const getLocale = (originUrl = window.location.origin) => {
    const urlParts = originUrl.split('.') 
    const urlSuffix = urlParts[urlParts.length - 1]
    const langCode = languageCodeDomains[urlSuffix]
    
    return langCode && langCode !== 'undefined' ? langCode : 'en-GB'
}

export const configLoader = async function configLoader(url) {
    const response = await fetch(url)
    window.CONFIG = await response.json()
}
