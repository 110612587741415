/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState, useCallback } from 'react'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import PropTypes from 'prop-types'
import { 
  Card, 
  CardActions, 
  CardContent,
  CircularProgress, 
  Grid,
  Typography,
} from '@mui/material'

import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { withTranslation } from 'react-i18next'
import { PrimaryButton } from '../../blocks/buttons/primary-button'
import { LabelCheckbox } from '../../blocks/input/label-checkbox'
import { ContainerLogo } from '../../composite/container/container-logo'
import CountDown from './count-down'
import './payment-page.css'

function PaymentPage({ order, paymentTables, additionalDataTables, adyenConfig, redirectResult, handleOnCountDownFinished, responseMessage, t, termsAndConditionsLabel, totalAmount, isValid, isProcessing }) {

  const { created, orderId, backButtonUrl } = order

  const dropinContainerRef = useRef(null)
  const [dropin, setDropin] = useState(null)
  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false)
  const [showRequiredWarning, setShowRequiredWarning] = useState(false)
  const [responseMessageVisible, setResponseMessageVisible] = useState(false)
  
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'))

  const handleOnSubmit = useCallback(() => {
    if (dropin && (!termsAndConditionsLabel || termsAndConditionsChecked)) {
      dropin.submit()
    }

    setShowRequiredWarning(!termsAndConditionsChecked)
  }, [termsAndConditionsChecked, termsAndConditionsLabel, dropin])

  const handleOnRetry = useCallback(() => {
    if (dropin) {
      dropin.setStatus()
      setResponseMessageVisible(false)
    }

  }, [dropin, setResponseMessageVisible])
    
  useEffect(() => {
    async function setupCheckout () {
    
      if(adyenConfig === null) return
      const checkout = await AdyenCheckout(adyenConfig)
      
      if ( redirectResult ) {
        checkout.submitDetails({ details: { redirectResult } })
      }

      const dropinCheckout = checkout.create('dropin').mount(dropinContainerRef.current)
      setDropin(dropinCheckout)
    }

    setupCheckout() 
    
  }, [adyenConfig, redirectResult, setDropin])

  useEffect(() => {
    if (dropin) {
      dropin.setStatus(responseMessage?.status, { message:  responseMessage?.message })
    }
    
    if (responseMessage) {
      setResponseMessageVisible(true)
    }
  }, [responseMessage, dropin, setResponseMessageVisible])

  return (
    <ContainerLogo logoMargin="0 0 15px">
      <Grid container justifyContent="space-between">
        <Grid item xs="auto">
          {backButtonUrl && <PrimaryButton color="muted" href={backButtonUrl}>{t('Kilroy.Payment.AdyenSkin.Button.Back', 'Back')}</PrimaryButton>}
        </Grid>
        { order.showCountDown && !order.isAlreadyPaid && orderId && 
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CountDown 
              orderId={orderId}
              created={created}
              backButtonUrl={backButtonUrl}
              handleOnFinished={handleOnCountDownFinished}
              t={t}
            />
          </Grid>
        }
      </Grid>
      <Grid container spacing={3} wrap="nowrap" direction={ largeScreen ? 'row' : 'column-reverse' } sx={{ pt: 1 }}>
        <Grid item xs={12} md={6}>
          <Card sx={{p: {xs: 0, sm: 3}, overflow: 'visible'}}>
            <CardContent>
              <div id="dropin-container" ref={dropinContainerRef} />
              {!dropin && 
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              }
            </CardContent>
            { isValid && 
              <CardActions>
                  {totalAmount && !responseMessageVisible && <PrimaryButton size="large" disabled={isProcessing} sx={{ mt: 1 }} color={termsAndConditionsLabel && !termsAndConditionsChecked ? 'muted' : 'primary'} onClick={handleOnSubmit} fullWidth>{`${t('Kilroy.Payment.AdyenSkin.Button.Pay', 'Pay')} ${totalAmount}`}</PrimaryButton>}
                  {responseMessageVisible && responseMessage?.status !== 'success' && <PrimaryButton color="muted" onClick={handleOnRetry} fullWidth>{t('Kilroy.Payment.AdyenSkin.Button.Retry', 'Retry')}</PrimaryButton>}
              </CardActions>
            }
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          { additionalDataTables && additionalDataTables }
          <Card sx={{p: {xs: 0, sm: 3}}}>
              {paymentTables && 
                <CardContent>
                  <Typography variant="h5" sx={{ textTransform: 'uppercase', pb: 1 }}>
                    {paymentTables.heading}
                  </Typography>
                  {paymentTables.description && <Typography variant="subtitle1">{paymentTables.description}</Typography>}
                  {paymentTables.tables}
                  {!responseMessageVisible && termsAndConditionsLabel && 
                    <LabelCheckbox
                      error={showRequiredWarning && !termsAndConditionsChecked}
                      label={termsAndConditionsLabel}
                      checked={termsAndConditionsChecked}
                      onChange={(e) => {setTermsAndConditionsChecked(e.target.checked)}}
                    />
                  }
                </CardContent>
              }
              {!paymentTables && (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>)
              }
          </Card>
        </Grid>
      </Grid>
    </ContainerLogo>
  )
}

const cardConfiguration = PropTypes.exact({
  card: PropTypes.exact({
    showPayButton: PropTypes.bool,
    hasHolderName: PropTypes.bool,
    holderNameRequired: PropTypes.bool,
    name: PropTypes.string,
    amount: PropTypes.exact({
      value: PropTypes.number,
      currency: PropTypes.string
    })
  }),
  mobilepay: PropTypes.exact({
    showPayButton: PropTypes.bool,
  })
})

PaymentPage.defaultProps = {
  adyenConfig: null,
  responseMessage: null,
  paymentTables: null,
  redirectResult: null,
  termsAndConditionsLabel: null,
  totalAmount: null,
  additionalDataTables: null
}

PaymentPage.propTypes = {
  order: PropTypes.object.isRequired, // eslint-disable-line
  adyenConfig: PropTypes.shape(
    {
      paymentMethodsResponse: PropTypes.object, // eslint-disable-line
      clientKey: PropTypes.string,
      locale: PropTypes.string,
      environment: PropTypes.string,
      paymentMethodsConfiguration:cardConfiguration,
      onSubmit: PropTypes.func,
      onAdditionalDetails: PropTypes.func,
    }
  ),
  handleOnCountDownFinished: PropTypes.func.isRequired,
  responseMessage: PropTypes.shape(
    {
      status: PropTypes.string,
      message: PropTypes.string
    }
  ),
  paymentTables: PropTypes.shape(
    {
      heading: PropTypes.string,
      description: PropTypes.string,
      tables: PropTypes.node,
    }
  ),
  additionalDataTables: PropTypes.node,
  isValid: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  redirectResult: PropTypes.string,
  t: PropTypes.func.isRequired,
  termsAndConditionsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  totalAmount: PropTypes.string
}

export default withTranslation()(PaymentPage)
  