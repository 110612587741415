import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: ['Italian Plate', 'Roboto', 'Sans Serif'].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1.875rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 900,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
    }
  },
  palette: {
    primary: {
      main: '#FF3C00',
    },
    secondary: {
      main: '#0070E4',
    },
    muted: {
      main: '#b7b7b7',
      dark: '#5e5c5c',
      light: '#e0dcdc',
      contrastText: '#fff',
    },
    logo: {
      primary: '#000000',
      secondary: '#FFFFFF',
      black: '#000000',
      white: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontWeight: 900,
          fontStyle: 'italic',
          letterSpacing: '0.025em'
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'small' && {
            fontSize: '.8em'
          }),
        })
      }
    }
  },
})