import { theme as kilroyTheme } from '../assets/kilroy/theme'
import { theme as jyskRejsebureauTheme } from '../assets/jysk-rejsebureau/theme'
import { theme as bennsTheme } from '../assets/benns/theme'
import { theme as isicTheme } from '../assets/isic/theme'
import { theme as winbergTheme } from '../assets/winberg/theme'

export const getBrandTheme = {
    benns: bennsTheme,
    isic: isicTheme,
    jr: jyskRejsebureauTheme,
    kilroy: kilroyTheme,
    winberg: winbergTheme,
}