/* eslint-disable no-restricted-syntax */
import { Typography } from '@mui/material'
import React from 'react'
import { CustomTable } from '../../composite/tables/custom-table'
import { formatPrice } from '../../helpers/format-price'

export const paymentTables = ({payment, booking, customer, currency, amount }, t) => {
  const { locale } = payment

  const orderLines = payment.orderLines ? payment.orderLines.map(orderLine => [orderLine.description, `${formatPrice((orderLine.amount / 100), locale)} ${currency}`]) : []

  const bookingInformationTranslationMap = {
    'TourId': 'TourIdLabel',
    'TourName' : 'TourNameLabel',
    'Duration' : 'DurationLabel',
    'Departure date' : 'DepartureDateLabel',
    'Return date' : 'ReturnDateLabel',
    'Number of Travellers' : 'NumberOfTravellersLabel',
    'Billing Address' : 'BillingAddressLabel',
  }

  const translatedBookingInformation = payment.bookingInformation.map( (item) => {
    if ( Object.keys(bookingInformationTranslationMap).includes(item.label) ) {
      return [
        t(`Kilroy.Payment.AdyenSkin.${bookingInformationTranslationMap[item.label]}`, item.label), 
        item.text
      ]
    }
 
    return [item.label, item.text]
    
  })

  return ({
    heading: t('Kilroy.Payment.AdyenSkin.Headline', 'Payment'),
    tables: 
      <>
        <Typography variant="h6" sx={{ pb: 1 }}>{t('Kilroy.NavigatorPayment.OrderDetails.Header', 'Booking information')}</Typography>
        <CustomTable
          tableRows={[
            [t('Kilroy.NavigatorPayment.CustomerDetails.FirstName', 'First Name'), customer.firstname],
            [t('Kilroy.NavigatorPayment.CustomerDetails.LastName', 'Last Name'), customer.lastname],
            [t('Kilroy.NavigatorPayment.CustomerDetails.Email', 'Email'), customer.email],
            [t('Kilroy.NavigatorPayment.OrderDetails.Reference', 'Booking Id'), booking.id],
            ...translatedBookingInformation
          ]}
        />
        <Typography variant="h6" sx={{ pb: 1, pt: 1 }}>{t('Kilroy.NavigatorPayment.PaymentSummaryTitle', 'Payment summary')}</Typography>
        <CustomTable
          highligtRowIndex={orderLines.length}
          tableRows={[
            ...orderLines,
            [t('Kilroy.NavigatorPayment.PaymentTotal', 'Total'), `${formatPrice((amount / 100), locale)} ${currency}`]
          ]}
        />
      </>
  })
}