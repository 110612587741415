/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import queryString  from 'query-string'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { get, post } from '../../common/bff-api'
import { getAdyenClientKey, getAdyenEnvironment } from '../../common/config'
import PaymentPage from './payment-page'
import { Link } from '../../blocks/buttons/link'
import { paymentTables } from './payment-tables'
import { additionalDataTables } from './additional-data-tables'
import { NotFoundPage } from '../not-found'
import { formatPrice } from '../../helpers/format-price'


function PaymentPageContainer({ t }) {
  const history = useHistory()
  
  const [ orderId, setOrderId ] = useState(null)
  const [ order, setOrder ] = useState(null)
  const [ redirectResult, setRedirectResult ] = useState(null)
  const [ sessionId, setSessionId ] = useState(null)
  const [ adyenConfig, setAdyenConfig ] = useState(null)
  const [ responseMessage, setResponseMessage ] = useState(null)
  const [ termsAndConditionsUrl, setTermsAndConditionsUrl ] = useState(null)
  const [ totalAmount, setTotalAmount ] = useState(null)
  const [ showNotFound, setShowNotFound ] = useState(false)
  const [ isValid, setIsValid ] = useState(true)
  const [ isProcessing, setIsProcessing ] = useState(false)

  const resultCodeMappings = useMemo(() => ({
    Authorised: 'success',
    Pending: 'loading',
    Received: 'loading',
    Refused: 'error',
    Error: 'error',
    Cancelled: 'error',
  }), [])

  const setupConfig = useCallback(async ({ amount, currency, successPageUrl, isAlreadyPaid }) => {
    if(isAlreadyPaid) {
        setResponseMessage({
            status: resultCodeMappings.Authorised,
            message: t('Kilroy.Payment.AdyenSkin.OrderAlreadyPaid', 'This order is already paid')
        })
    }

    const session = {}
    if ( sessionId ) {
      session.id = sessionId
      session.sessionData = undefined
    } else {
      const startSession = await post('payment/session', {
        orderId
      })
      session.id = startSession.sessionId
      session.sessionData = startSession.sessionData
    }
    if (isAlreadyPaid) {
      setAdyenConfig({
        session,
        clientKey: getAdyenClientKey(),
        environment: getAdyenEnvironment(),
        onPaymentCompleted: (result) => {
          if ( result.resultCode === 'Authorised') {
            window.location.href = successPageUrl
          }
        },
        onError: (error) => {
          console.log('error', error)
        },
      })
    } else {

      setAdyenConfig({
        session,
        clientKey: getAdyenClientKey(),
        environment: getAdyenEnvironment(),
        onPaymentCompleted: (result) => {
          if ( result.resultCode === 'Authorised') {
            window.location.href = successPageUrl
          } else {
            setIsProcessing(false)
            setResponseMessage({
              status: resultCodeMappings[result.resultCode],
              message: result.resultCode
            })
          }
        },
        paymentMethodsConfiguration: {
          card: {
            showPayButton: false,
            hasHolderName: true,
            holderNameRequired: true,
            name: t('Kilroy.Payment.AdyenSkin.Card.Name','Credit or debit card'),
            amount: {
              value: amount,
              currency
            }
          },
          mobilepay: {
            showPayButton: false
          },
        },
        onError: (error) => {
          console.log('error', error)
          setResponseMessage({
            status: resultCodeMappings.Error,
            message: error.message
          })
        },
        beforeSubmit(data, component, actions){
          setIsProcessing(true)
          actions.resolve(data)
        }
      })
    }

  }, [orderId, sessionId, setAdyenConfig, setResponseMessage, resultCodeMappings, t])

  const showValidationOnLoad = useCallback(() => {
    const parsedQueryString = queryString.parse(history.location.search)

    if(!parsedQueryString.resultCode) return

    setResponseMessage({
      status: resultCodeMappings[parsedQueryString.resultCode] || resultCodeMappings.Error,
      message:  parsedQueryString.refusalReason && parsedQueryString.refusalReason !== 'undefined' ? parsedQueryString.refusalReason : null
    })
  }, [history, resultCodeMappings, setResponseMessage])

  useEffect(() => {
    if(!orderId) return

    (async () => {
      try {
        const fetchedOrder = await get(`payment/order/${orderId}`, { cache: 'no-cache' })
        setOrder(fetchedOrder)
      } catch (error) {
        console.info(error)
        setShowNotFound(true)
      }
    })()

  }, [orderId])

  useEffect(() => {
    if(!order) return
    setupConfig(order)
    setTotalAmount(`${formatPrice((order.amount / 100), order.payment.locale)} ${order.currency}`)
    if (order.termsAndConditionsUrl) {
      const termsAndConditionsLink = 
        <Typography variant="body1">
          {t('Kilroy.NavigatorPayment.TermsAndConditionsCheckBoxText','Please accept our')} <Link
            href={order.termsAndConditionsUrl}
            target="blank"
          >{t('Kilroy.NavigatorPayment.TermsAndConditionsLinkText','terms and conditions')}</Link>
        </Typography>
      setTermsAndConditionsUrl(termsAndConditionsLink)
    }

    showValidationOnLoad() 
  }, [
    order,
    setupConfig,
    showValidationOnLoad,
    setTermsAndConditionsUrl,
    setShowNotFound,
    t
  ])

  useEffect(() => {
    const parsedQueryString = queryString.parse(history.location.search)

    setOrderId(parsedQueryString.orderId)
    setRedirectResult(parsedQueryString.redirectResult)
    setSessionId(parsedQueryString.sessionId)
  }, [setOrderId, setRedirectResult, setSessionId, history])

  const handleOnCountDownFinished = () => {
    setResponseMessage({
      status: resultCodeMappings.Error,
      message: t('Kilroy.Payment.AdyenSkin.CountDownExpiredText')
    }) 
    setIsValid(false)
  }

  if (showNotFound) {
    return (<NotFoundPage/>)
  }

  return (
    <>
      { order && 
        <PaymentPage
          order={order}
          adyenConfig={adyenConfig}
          handleOnCountDownFinished={handleOnCountDownFinished}
          responseMessage={responseMessage}
          paymentTables={paymentTables(order, t)}
          additionalDataTables={order.additionalData ? additionalDataTables(order.additionalData, t) : null}
          isValid={isValid}
          isProcessing={isProcessing}
          redirectResult={redirectResult}
          termsAndConditionsLabel={termsAndConditionsUrl}
          totalAmount={totalAmount}
        />
      }
    </>
  )
}

PaymentPageContainer.propTypes = {
  t: PropTypes.func.isRequired
}


export default withTranslation()(PaymentPageContainer)
  